import React from "react";
import { CheckIcon } from "@heroicons/react/solid";

const KeyPointItem = ({ text }) => (
  <div key={text} className="relative">
    <dt>
      <CheckIcon
        className="absolute h-6 w-6 text-green-500"
        aria-hidden="true"
      />
      <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{text}</p>
    </dt>
  </div>
);

export default KeyPointItem;
