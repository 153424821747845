import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";
import { formatText } from "../utils/utils";

const OldGenerationSection = ({
  highlightedTitle,
  title,
  description,
  imgName,
  imgTitle,
  imgDescription,
}) => (
  <div className="max-w-7xl mx-auto relative mt-16 sm:mt-20">
    <div className="relative">
      <div className="relative mx-auto py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-8 text-lg tracking-tight">
          {/* first column */}
          <div>
            <GatsbyImage
              image={useImageForData(imgName)}
              className="block mx-auto object-cover h-full"
              imgClassName="block mx-auto object-cover h-full"
              alt={imgTitle}
            />
          </div>
          {/* second column */}
          <div className="grid gap-8 auto-rows-min">
            <div className="p-4 bg-slate-700 text-center">
              <h2 className="text-3xl font-bold text-orange-500">
                {highlightedTitle}
                <span className="text-white">{title}</span>
              </h2>
            </div>
            <p className="text-xl text-gray-800 font-semibold">{description}</p>
            <div>
              <p
                className="text-gray-800 font-medium"
                dangerouslySetInnerHTML={{
                  __html: formatText(imgTitle, "orange-500"),
                }}
              />
              <p className="text-lg text-gray-500 mt-4">{imgDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OldGenerationSection;
