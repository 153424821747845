import React from "react";

const KeyPointsSection = ({ title, highlightedTitle, children }) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto pt-16 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto p-4 md:pl-20 bg-slate-700 text-center md:text-left">
        <h2 className="text-3xl font-bold text-white">
          {title}
          <span className="text-orange-500">{highlightedTitle}</span>
        </h2>
      </div>
      <dl className="mt-12 space-y-10 sm:space-y-0 grid sm:gap-x-6 sm:gap-y-10 justify-center">
        {children}
      </dl>
    </div>
  </div>
);

export default KeyPointsSection;
