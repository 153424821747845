import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";
import { formatText } from "../utils/utils";

const NextGenerationSection = ({ title, imgName, description, solutions }) => (
  <div className="pt-20 pl-4 sm:pt-16 sm:pl-6 lg:pl-8">
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div className="sm:text-center lg:text-left">
            <h2 className="tracking-tight font-extrabold text-gray-900 text-xl sm:text-3xl">
              <span
                className="block"
                dangerouslySetInnerHTML={{
                  __html: formatText(title[0], "orange-500"),
                }}
              />{" "}
              <span
                className="block"
                dangerouslySetInnerHTML={{
                  __html: formatText(title[1], "orange-500"),
                }}
              />
            </h2>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              {description}
            </p>
            <ul className="mt-5 mx-auto lg:mx-0 sm:mt-8 sm:grid gap-4 sm:justify-center lg:justify-start sm:max-w-xl list-disc list-inside text-left">
              {solutions.map((text, i) => (
                <li
                  key={i}
                  className="text-base text-gray-500 sm:text-lg md:text-xl"
                  dangerouslySetInnerHTML={{
                    __html: formatText(text, "orange-500"),
                  }}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <GatsbyImage
          image={useImageForData(imgName)}
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          imgClassName="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          alt={title[0]}
        />
      </div>
    </div>
  </div>
);
export default NextGenerationSection;
