import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const ExperienceSection = ({ firstTitle, secondTitle, imgName, children }) => (
  <div className="max-w-7xl mx-auto relative">
    <div className="relative">
      <div className="relative mx-auto py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
        <h2 className="text-xl leading-8 grid grid-cols-2 sm:grid-cols-2 text-center font-extrabold tracking-tight sm:text-3xl gap-8">
          <span className="text-right text-[#06293d]">{firstTitle}</span>
          <span className="text-left text-orange-500">{secondTitle}</span>
        </h2>
        <div className="mt-4 sm:mt-8 grid md:grid-cols-2 gap-8 text-lg tracking-tight">
          {/* first column */}
          <div>
            <div className="mt-4 sm:mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2">
              {children}
            </div>
          </div>
          {/* second column */}
          <div>
            <GatsbyImage
              image={useImageForData(imgName)}
              className="block mx-auto object-cover h-full"
              imgClassName="block mx-auto object-cover h-full"
              alt={secondTitle}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ExperienceSection;
