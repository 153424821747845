import { CheckIcon } from "@heroicons/react/solid";
import React from "react";

const FeaturesCCUSection = ({ title, subtitles, features }) => (
  <div className="bg-slate-700 mt-20 sm:mt-16">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-base font-semibold text-orange-200 uppercase tracking-wide">
          {title}
        </h2>
        <div className="mt-2 grid grid-cols-2 text-white text-xl leading-8 text-center font-extrabold tracking-tight sm:text-3xl gap-8">
          <span className="text-right">{subtitles[0]}</span>
          <span className="text-left">{subtitles[1]}</span>
        </div>
      </div>
      <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-8 lg:grid-cols-4">
        {features.map((feature) => (
          <div key={feature} className="relative">
            <dt>
              <CheckIcon
                className="absolute h-6 w-6 text-green-500"
                aria-hidden="true"
              />
              <p className="ml-9 text-lg leading-6 font-medium text-white">
                {feature}
              </p>
            </dt>
          </div>
        ))}
      </dl>
    </div>
  </div>
);

export default FeaturesCCUSection;
