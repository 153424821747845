import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const OtherIssuesSection = ({ title, highlightedTitle, imgName, children }) => (
  <div className="bg-white max-w-7xl mx-auto mb-8">
    <div className="pt-20 px-4 sm:pt-16 sm:px-6 lg:px-8">
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <GatsbyImage
              image={useImageForData(imgName)}
              className="h-60 lg:h-full w-full object-cover xl:absolute xl:inset-0"
              imgClassName="h-60 lg:h-full w-full object-cover xl:absolute xl:inset-0"
              alt={title}
            />
          </div>
        </div>
        <div className="relative pt-12 px-4 pt-8 lg:pt-0 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <div className="p-4 bg-slate-700 text-center">
                <h2 className="text-3xl font-bold text-white">
                  {title}
                  <span className="text-orange-500">{highlightedTitle}</span>
                </h2>
              </div>
              {/* <h2 className="text-3xl font-extrabold text-white">
                {title}
                <span className="text-orange-500">{highlightedTitle}</span>
              </h2> */}
              {/* <h2 className="leading-6 text-amber-600 font-semibold tracking-wide uppercase">
                {title}
              </h2> */}
              <div className="mt-12 space-y-10 text-gray-500">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OtherIssuesSection;
