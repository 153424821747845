import React from "react";
import Logotype from "../../images/ascend-logo.png";
import Link from "gatsby-link";

const Header = (props) => {
  const { children } = props;
  return (
    <header>
      <div className="flex flex-col gap-2 sm:flex-row sm:gap-0 justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <Link to="/" className="whitespace-nowrap text-base font-medium text-gray-900">
            {/* <img className="h-20 w-auto sm:h-22" src={Logotype} alt="" /> */}
            Axena Company Service AB
          </Link>
        </div>

        <div className=" flex items-center justify-end flex-1 lg:w-0  gap-6">
          {children}
        </div>
        {/*<Link
          to="/authorization"
          className=" whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange-600 hover:bg-orange-700"
        >
          Sign in
        </Link>
        */}
      </div>
    </header>
  );
};

export default Header;
