import * as React from "react";
import {
  BriefcaseIcon,
  CurrencyEuroIcon,
  CursorClickIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
  ServerIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/solid";

import "../styles/base.css";

import Header from "../components/sections/Header";
import HeaderLink from "../components/elements/HeaderLink";
import FirstScreen from "../components/sections/FirstScreen";
import Features from "../components/sections/Features";
import FeatureItem from "../components/elements/FeatureItem";
import Form from "../components/sections/Form";
import WhoWeSection from "../components/sections/WhoWeSection";
import ExperienceSection from "../components/sections/ExperienceSection";
import ExperienceItem from "../components/elements/ExperienceItem";
import KeyPointsSection from "../components/sections/KeyPointsSection";
import KeyPointItem from "../components/elements/KeyPointItem";
import OldGenerationSection from "../components/sections/OldGenerationSection";
import ProblemsSection from "../components/sections/ProblemsSection";
import ProblemItem from "../components/elements/ProblemItem";
import OtherIssuesSection from "../components/sections/OtherIssuesSection";
import OtherIssueItem from "../components/elements/OtherIssueItem";
import NextGenerationSection from "../components/sections/NextGenerationSection";
import FeaturesCCUSection from "../components/sections/FeaturesCCUSection";
import InvestSection from "../components/sections/InvestSection";

const IndexPage = () => {
  return (
    <div className="bg-white">
      <Header>
        <HeaderLink name={"Features"} target={"#features"} />
        {/* <HeaderLink name={"Pricing"} target={"#pricing"} /> */}
        <HeaderLink name={"Contacts"} target={"#contacts"} />
      </Header>
      <FirstScreen
        title="Welcome to our Assessio concept called CCCC-SaaS"
        subtitle="which stands for Clone Certified Companies Content - Software as a Service"
        description="This is a fiscal server solution for companies with 2-3000+ POS in the market and will give you a ROI (Return Of Investment) in 2-5 years"
        callToAction="Contact us"
      />

      {/*
      <Trusts title="TRUSTED BY" className="items-center">
        <TrustItem src={BraveIcon} description={"brave developers"} />
        <TrustItem src={GodaIcon} description={"GodaGrannen"} />
        <TrustItem src={DvigexIcon} description={"Dvigex"} />
        <TrustItem src={TestCenterIcon} description={"Test center"} />
        <TrustItem src={EmotianIcon} description={"Emotian"} />
        <TrustItem src={ElfsterIcon} description={"Elfster"} />
        <TrustItem src={Wally} description={"Wally"} />
        <TrustItem src={PinpointerIcon} description={"Pinpointer"} />
      </Trusts> */}

      <Features
        title="Benefits for the client"
        id="features"
        subtitle="Features"
        description="We set up a total cloned solution from our certified solution either as an independent company or as an asset by you as the client. It includes source codes, certificates by an independent certification body, and installation in server halls including redundancy installations. Your license allows you to have external POS clients but not to clone the CCCC-SaaS concept. We can train special staff as security officers, certification managers, etc.  to comply with the rules and regulations set up by the authority for fiscal server services. Or we can supply you with this service."
      >
        <FeatureItem
          name={
            "You choose the level of support, programming etc you want either you buy those services from us or we train your staff for the tasks"
          }
          description={""}
        />
        <FeatureItem
          name={
            "We can train special staff as security officers to comply with the rules and regulations set up by the authority for fiscal server services. Or we can supply you with this service"
          }
          description={""}
        />
        <FeatureItem
          name={
            "Time to market will be decreased instantly since we set you up on our fiscal server on the same terms as if you had your own while we set up your solution"
          }
          description={""}
        />
        <FeatureItem
          name={
            "The CCCC - SaaS solution will be very cost-efficient for you and also allocate a minimum of resources from your management, administration, and development team"
          }
          description={""}
        />
      </Features>

      {/* <Prices
        id="prices"
        title="Pricing plans for teams of all sizes"
        description="Choose an affordable plan that's packed with the best features for engaging your team and flexible project management."
      >
        <PriceItem
          name={"Basic"}
          price={99}
          description={"Recommended for up to 20 users"}
          ramCapacity={"4GB"}
          coresAmount={"2x"}
          ssdCapacity={"60GB"}
          callToAction={"Bill monthly"}
        />
        <PriceItem
          name={"Starter"}
          price={299}
          recommended={true}
          description={"Recommended for up to 100 users"}
          ramCapacity={"32GB"}
          coresAmount={"8x"}
          ssdCapacity={"120GB"}
          callToAction={"Bill monthly"}
        />
        <PriceItem
          name={"Ultimate"}
          price={990}
          description={"Recommended for up to 1000 users"}
          ramCapacity={"128GB"}
          coresAmount={"32x"}
          ssdCapacity={"1000GB"}
          callToAction={"Bill monthly"}
        />
      </Prices> */}

      <WhoWeSection
        title="*WHO* WE ARE"
        boldTitle="extrabold"
        description="*Axena* *Company* *Service* *AB* is a market-leading and experienced manufacturer of control units and developers of fiscal servers that abides by the law and certification requirements set by the Swedish Government for the Swedish market. We are the only independent supplier of fiscal services in Sweden, so we are not owned by competing POS companies."
        boldDescription="bold"
        secondColor="orange-500"
        imgName="people.jpg"
      />

      <ExperienceSection
        firstTitle="EXPERIENCE"
        secondTitle="MATTERS"
        imgName="goUp.jpg"
      >
        <ExperienceItem
          IconImg={PresentationChartLineIcon}
          description="Long experience of failures made by hosting companies allows us to choose the best ones"
        />
        <ExperienceItem
          IconImg={BriefcaseIcon}
          description="Our technical support teams were experienced on hundreds of cases and created a significant knowledge base on dozens of client’s architectures"
        />
        <ExperienceItem
          IconImg={UserGroupIcon}
          description="Based on the client’s cases our development team designed 9-steps on-boarding process for 3rd party cash register developers"
        />
      </ExperienceSection>

      <KeyPointsSection title="Regulation " highlightedTitle="key points">
        <KeyPointItem text="The cash register must be connected to the control unit" />
        <KeyPointItem text="Every receipt must be logged in the control unit" />
        <KeyPointItem text="Each cash register must be registered in Tax Agency" />
      </KeyPointsSection>

      <OldGenerationSection
        highlightedTitle="Old generation "
        title="Cloud Control Unit"
        description="Accordingly to the Swedish Regulation fiscal data must be stored on hardware control units only"
        imgName="gen.png"
        imgTitle="WHAT IS *CLOUD* *CONTROL* *UNIT* ?"
        imgDescription="It is set of physical Control Units connected to server via Internet and may be used by multiple customers"
      />

      <ProblemsSection
        highlightedTitle="Problems "
        title="of old solution"
        imgName="ihc-500.jpg"
      >
        <ProblemItem
          title="Unstable internet"
          description="e.g. No internet at POS will lead impossibility to print a receipt – it’s possible only after Control Unit receives data"
        />
        <ProblemItem
          title="Fault-intolerant"
          description="control unit  - any hardware failure may result full Cloud Control Unit malfunctioning"
        />
      </ProblemsSection>

      <OtherIssuesSection
        title="OTHER "
        highlightedTitle="SUPPORT ISSUES"
        imgName="human_factor.jpg"
      >
        <OtherIssueItem
          text="Merchants are not responsible enough"
          IconImg={UserIcon}
        />
        <OtherIssueItem
          text="We have to take care about registering their cash registers"
          IconImg={DocumentTextIcon}
        />
        <OtherIssueItem
          text="Merchants may provide inaccurate data"
          IconImg={ExclamationCircleIcon}
        />
        <OtherIssueItem text="High support costs" IconImg={CurrencyEuroIcon} />
        <OtherIssueItem
          text="3rd party cash register developers are not competent enough in cash register regulations"
          IconImg={CursorClickIcon}
        />
      </OtherIssuesSection>

      <NextGenerationSection
        title={[
          "*NEXT* *GENERATION* of  Cloud",
          "Control Unit - *CONTROL* *SYSTEM*",
        ]}
        imgName="next_gen.jpeg"
        description="Old solution will be updated to adjust the service to modern and secure technical and market demands for fiscal server solutions:"
        solutions={[
          `New Swedish Tax Agency’s regulation on control systems for cash registers (SKVFS 2020:9) solves *bad* *internet* *issue* using *buffering* *on* *client* *side*`,
          `This new regulation allows redundancy  which makes solution *fault* *tolerant*`,
        ]}
      />

      <FeaturesCCUSection
        title="Features"
        subtitles={["CCU NEXT GENERATION", "CONTROL SYSTEM"]}
        features={[
          "Storing fiscal data in the control unit using modern network protocol with top security",
          "Maintenance processes",
          "Smooth-n-easy on-boarding for cash register developers",
          "Developer’s portal with API and documentation",
          "Flexible API for client’s architectures",
          "Automatic generation of invoice specifications ",
          "Merchant’s portal for data overview",
          "Cash register developer’s portal for data overview",
        ]}
      />

      <InvestSection
        title="*RETURN* ON INVESTMENT"
        description={[
          "This proposal is intended for partners who can *recoup* *the* *investment* *in* *2-5* *years* .",
          "We can also provide the possibility of financing. Depending on how you want to handle the fiscal services as a cost or asset, we adjust the solution so it corresponds to your wishes.",
        ]}
        imgName="roi.jpg"
      />

      <Form
        phone="+46 (0)70 594 29 54"
        title="Contact information"
        submitButton="Send"
        submitButtonSuccess="Success"
        description="Contact Douglas Oest CEO for an offer according to your specific demands"
        id="contacts"
      />
    </div>
  );
};

export default IndexPage;
