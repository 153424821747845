export const initialFormFields = {
  name: "",
  phone: "",
  email: "",
  message: "",
};

export const formatText = (str, color, bold) => {
  const arr = str.split(" ");
  arr.map((substr) => {
    if (substr.includes("*")) {
      let word = substr.substring(1, substr.length - 1);
      str = str.replace(
        substr,
        `<span class="text-${color} ${bold && "font-" + bold}">` + word + "</span>"
      );
    }
  });
  return str;
};
// <p
// className="text-slate-800"
// dangerouslySetInnerHTML={{
//   __html: formatText(par, secondColor, "bold"),
// }}
// />
