import * as React from "react";

const ExperienceItem = ({ IconImg, description }) => (
  <div className="pt-6 relative">
    <div className="flow-root h-full bg-gray-50 rounded-lg px-6 pb-8">
      <div className="-mt-6">
        <div className="lg:text-center">
          <span className="inline-flex items-center justify-center p-3 bg-slate-800 rounded-md shadow-lg">
            <IconImg className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
        </div>
        <p className="mt-5 text-base text-gray-500">{description}</p>
      </div>
    </div>
  </div>
);
export default ExperienceItem;
