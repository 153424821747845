import React from "react";

const OtherIssueItem = ({ text, IconImg }) => (
  <div key={text} className="relative">
    <dt>
      <div className="absolute h-12 w-12 bg-gradient-to-r from-cyan-600 to-slate-600 rounded-md flex items-center justify-center">
        <IconImg className="h-6 w-6 text-white" aria-hidden="true" />
      </div>
      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
        {text}
      </p>
    </dt>
  </div>
);

export default OtherIssueItem;
