import React from "react";

const ProblemItem = ({ title, description }) => (
  <p key={title}>
    <span className="block text-2xl font-bold text-orange-200">{title}</span>
    <span className="mt-1 block text-base text-gray-300">
      <span className="font-medium text-white">{description}</span>
    </span>
  </p>
);

export default ProblemItem;
